var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-testid":"step-import"}},[_c('VContainer',[_c('div',{staticClass:"d-flex justify-center align-center flex-column mt-5"},[_c('h1',{staticClass:"font-weight-bold",attrs:{"data-testid":"stepper:step-title"}},[_vm._v(_vm._s(_vm.$t('step.import.title')))]),_vm._t("fileNameContainer")],2),_c('VCol',[_c('VRow',{staticClass:"d-flex justify-end mt-n10"},[_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":"","outlined":"","data-testid":"reload-file-rows","loading":_vm.reloadRequestBag.loading,"color":"primary"},on:{"click":_vm.reloadFileRows}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v("fal fa-redo")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('step.import.action.reloadStatus')))])])],1),(_vm.isStoreReady)?[_c('VRow',{staticClass:"mt-2"},[_c('VDataTable',{staticClass:"flex-grow-1",attrs:{"fixed-header":"","show-select":"","headers":_vm.headers,"items":_vm.fileRows,"options":_vm.tableOptions,"footer-props":_vm.footerOptions,"data-testid":"import-items-holder"},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return [_c('VTooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('VCheckbox',{staticClass:"text-white",attrs:{"data-testid":"global-toggle","dark":""},on:{"change":_vm.toggleSelect},model:{value:(_vm.toggleFileRowImport),callback:function ($$v) {_vm.toggleFileRowImport=$$v},expression:"toggleFileRowImport"}})],1)]}}],null,false,832666729)},[_c('span',[_vm._v(_vm._s(_vm.$t('step.import.headers.selectAll')))])])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('FileRowImport',{key:("file-row-item-" + (item.id)),attrs:{"file-row":item,"property-selector":_vm.propertySelector},on:{"showError":_vm.handleShowError},scopedSlots:_vm._u([{key:"checkbox",fn:function(){return [_c('VCheckbox',{attrs:{"input-value":_vm.isSelected(item),"disabled":!_vm.isSelectable(item),"data-testid":"file-row-toggle"},on:{"change":function (status) { return _vm.setIsSelected(item, status); }}})]},proxy:true}],null,true)})]}}],null,false,3746434741)})],1)]:[_c('VSkeletonLoader',{staticClass:"flex-grow-1",attrs:{"type":"table","data-testid":"import-step:skeleton-loader"}})]],2)],1),_c('VRow',{staticClass:"d-flex"},[_c('VBtn',{staticClass:"flex-1-1-0",attrs:{"x-large":"","tile":"","data-testid":"stepper:cancel-action","color":"lightPurple"},on:{"click":_vm.returnToHome}},[_vm._v(" "+_vm._s(_vm.$t('action.returnHome'))+" ")]),_c('VBtn',{staticClass:"flex-1-1-0 primary-gradient white--text rounded-br-lg",attrs:{"x-large":"","tile":"","data-testid":"import","loading":_vm.importRequestBag.loading},on:{"click":_vm.importClicked}},[_vm._v(" "+_vm._s(_vm.$t('action.import'))+" ")])],1),_c('ModalShowImportError',{ref:"modalShowError"}),_c('ModalConfirmationImport',{ref:"modalConfirmationImport",on:{"confirm":function (options) { return _vm.handleImportClicked(options.force); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }