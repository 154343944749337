














































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
  model: {
    prop: 'file',
  },
})
export default class DropZone extends Vue {
  @Prop({ default: null })
  private readonly file!: File | null;
  @Prop({ type: Array, default: () => [] })
  private readonly errorMessages!: Array<string>;
  @Prop({ type: String, default: null })
  private readonly classDropzone!: string | null;

  private active: boolean = false;

  $refs!: {
    inputFile: HTMLInputElement;
  };

  get internalValue(): File | null {
    return this.file;
  }

  set internalValue(file: File | null) {
    this.$emit('input', file);
  }

  get hasFile(): boolean {
    return this.internalValue !== null;
  }

  get hasErrors(): boolean {
    return this.errorMessages.length > 0;
  }

  openBrowserFile(): void {
    this.$refs.inputFile.click();
  }

  onFileChange(e: Event): void {
    const target = e.target! as HTMLInputElement;

    if (target.files !== null) {
      this.internalValue = target.files[0];
      this.$refs.inputFile.value = '';
    }
  }

  onDragOver(e: DragEvent): void {
    this.active = true;
  }

  onDragLeave(e: DragEvent): void {
    this.active = false;
  }

  onDrop(e: DragEvent): void {
    this.active = false;

    if (e.dataTransfer!?.files!.length > 0) {
      this.internalValue = e.dataTransfer!?.files[0] || null;
    }
  }
}
