







































































import { Component, Mixins } from 'vue-property-decorator';
import { StepMixin } from '@/mixins/StepMixin';
import FileRowValidation from '@/components/FileRowValidation.vue';
import FileRowValidationStats from '@/components/FileRowValidationStats.vue';
import { MapperTypes } from '@/types/enum';
import { sortFileRowErrors } from '@/utils';
import ModalUpdateFileRow from '@/components/ModalUpdateFileRow.vue';
import { NotificationMessage } from '@/services/notifications';
import { makeRequestBag } from '@/utils/request-bag';
import NotifyMixin from '@/mixins/NotifyMixin';
import ModalDataValidationWarning from '@/components/ModalDataValidationWarning.vue';

@Component({
  components: { ModalDataValidationWarning, ModalUpdateFileRow, FileRowValidationStats, FileRowValidation },
})
export default class StepDataValidation extends Mixins(StepMixin, NotifyMixin) {
  private static UPDATE_HEADERS = ['lineNumber', 'siteId', 'lineValid', 'actions'];
  private static CREATE_HEADERS = ['lineNumber', 'company', 'lineValid', 'actions'];
  private fileRowRequestBag = makeRequestBag({ data: [] as Array<App.ObjectName> });

  $refs!: {
    modalUpdateFileRow: ModalUpdateFileRow;
    modalDataValidationWarning: ModalDataValidationWarning;
  };

  get isStoreReady(): boolean {
    return !this.$store.state.dataValidation.requestBag.loading && this.$store.state.dataValidation.initialized;
  }

  get currentImport(): App.Import {
    return this.$store.getters['importFile/currentImport'];
  }

  get headers(): Array<string> {
    return this.currentImport.updateFile ? StepDataValidation.UPDATE_HEADERS : StepDataValidation.CREATE_HEADERS;
  }

  get fileRows(): Array<App.FileRowError> {
    const fileRows = this.currentImport.fileRows as Array<App.FileRowError>;
    if (this.isStoreReady && null !== fileRows) {
      return [...fileRows].sort(sortFileRowErrors);
    }
    return [];
  }

  get propertySelector(): MapperTypes {
    return this.currentImport.updateFile ? MapperTypes.SITE_ID : MapperTypes.ENTERPRISE;
  }

  private handleOpenFileRowModal(fileRow: App.FileRow): void {
    this.$refs.modalUpdateFileRow.open(fileRow);
  }

  private async handleUpdatedFileRow(fileRow: App.FileRow): Promise<void> {
    this.fileRowRequestBag.reset({ data: [], loading: true });
    try {
      const { fileRowCells } = fileRow;
      const fileRowRequestObject = {
        fileRowCells: fileRowCells.map((fileRowCell: App.FileRowCell) => {
          return { id: fileRowCell['@id'], value: fileRowCell.value };
        }),
      };

      const response = await this.$apiClient.patch(fileRow['@id'], fileRowRequestObject, {
        headers: { 'Content-Type': 'application/merge-patch+json' },
        params: {
          errors: true,
        },
      });
      this.notify(NotificationMessage.success(this.$t('step.dataValidation.alertFileRowUpdated').toString()));

      if (response.data) {
        delete response.data['@context'];
        fileRow = response.data;
      }

      if (this.fileRows) {
        const fileRows = this.fileRows.map(currentFileRow =>
          currentFileRow.id === fileRow.id ? fileRow : currentFileRow
        );

        this.$store.dispatch('importFile/updateFileRowErrors', fileRows);
      }
    } catch (e) {
      this.notify(NotificationMessage.error(e.message));
      throw e;
    } finally {
      this.fileRowRequestBag.loading = false;
    }
  }

  private openWarningModal(): void {
    const hasErrors =
      this.fileRows.filter(fileRow => fileRow.errors.length > 0 || fileRow.warnings.length > 0).length > 0;

    if (hasErrors) {
      this.$refs.modalDataValidationWarning.open();
      return;
    }

    this.$emit('toNextStep');
  }
}
