







import { Component, Vue } from 'vue-property-decorator';
import ImportFileList from '@/components/ImportFileList.vue';
import Upload from '@/components/Upload.vue';

@Component({
  components: { Upload, ImportFileList },
  metaInfo(this: ViewHome) {
    return {
      title: 'Accueil',
      titleTemplate: `%s – ${process.env.VUE_APP_NAME}`,
    };
  },
})
export default class ViewHome extends Vue {}
