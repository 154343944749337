
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FileRowValidationStats extends Vue {
  @Prop({ required: true })
  private readonly fileRows!: Array<App.FileRowError>;

  get erroredLines(): Array<App.FileRowError> {
    return this.fileRows.filter(fileRow => fileRow.errors.length > 0);
  }
}
