









import { Component, Prop, Vue } from 'vue-property-decorator';
import { FileRowImportStatus } from '@/types/enum';

@Component
export default class FileRowImportStatusBadge extends Vue {
  @Prop({ required: true })
  private readonly status!: FileRowImportStatus;

  private statusConfiguration = {
    [FileRowImportStatus.NEW]: {
      color: '',
      message: 'new',
    },
    [FileRowImportStatus.POST]: {
      color: 'info',
      message: 'post',
    },
    [FileRowImportStatus.REPOST]: {
      color: 'info',
      message: 'repost',
    },
    [FileRowImportStatus.ERROR]: {
      color: 'error',
      message: 'error',
    },
    [FileRowImportStatus.SUCCESS]: {
      color: 'success',
      message: 'success',
    },
  };

  get color(): string {
    return this.statusConfiguration[this.status].color;
  }

  get message(): string {
    return this.statusConfiguration[this.status].message;
  }

  get isErrorStatus(): boolean {
    return this.status === FileRowImportStatus.ERROR;
  }
}
