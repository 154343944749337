import { Component, Prop, Vue } from 'vue-property-decorator';
import { MapperTypes } from '@/types/enum';

@Component
export default class FileRowItemMixin<Entity extends App.FileRowError> extends Vue {
  @Prop({ required: true })
  public readonly fileRow!: Entity;

  @Prop({ required: true })
  protected readonly propertySelector!: MapperTypes;

  get displayedProperty(): string {
    const displayedCell = this.fileRow.fileRowCells.find(
      (fileRowCell: App.FileRowCell) => fileRowCell.mapping?.type === this.propertySelector
    );

    if (undefined === displayedCell) {
      return this.$t('step.dataValidation.notFoundProperty').toString();
    }

    return displayedCell.value as string;
  }
}
