






















































import { makeRequestBag } from '@/utils/request-bag';
import { Component, Mixins } from 'vue-property-decorator';
import { NotificationMessage } from '@/services/notifications';
import NotifyMixin from '@/mixins/NotifyMixin';

@Component
export default class ModalImportDelete extends Mixins(NotifyMixin) {
  private item: App.Import | null = null;
  private requestBag = makeRequestBag({ data: [] });
  private visible: boolean = false;

  $refs!: {
    modal: Vue & {
      persistent: boolean;
    } /* "InstanceType<typeof VDialog>" does not work, see https://github.com/vuetifyjs/vuetify/issues/5962 */;
  };

  open(item: App.Import): void {
    this.visible = true;
    this.item = item;
  }

  hide(): void {
    this.requestBag.reset();
    this.visible = false;
  }

  private onClickOutside(): void {
    if (this.$refs.modal.persistent) {
      return;
    }

    this.hide();
  }

  private onCancel(): void {
    this.hide();
  }

  private onDelete(e: Event): void {
    e.preventDefault();
    this.delete();
  }

  private onClose(): void {
    this.hide();
  }

  private async delete(): Promise<void> {
    if (!this.item) {
      return;
    }

    this.requestBag.reset({ data: [], loading: true });

    try {
      await this.$apiClient.delete(`/api/imports/${this.item.id}`);
      this.$emit('deleted');
      this.requestBag.success = true;

      this.notify(NotificationMessage.success(this.$t('home.deleteModal.alertSuccess').toString()));
    } catch (e) {
      this.requestBag.error = true;
      this.requestBag.errorMessage = e.message;

      this.notify(NotificationMessage.error(e.message));
    } finally {
      this.requestBag.loading = false;
      this.hide();
    }
  }
}
