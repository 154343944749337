





























































































import { Component, Mixins } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { makeRequestBag } from '@/utils/request-bag';
import { NotificationMessage } from '@/services/notifications';
import NotifyMixin from '@/mixins/NotifyMixin';
import { ImportStep } from '@/types/enum';
import RoutingMixin from '@/mixins/RoutingMixin';
import DropZone from '@/components/DropZone.vue';

@Component({
  components: { ValidationObserver, ValidationProvider, DropZone },
})
export default class Upload extends Mixins(NotifyMixin, RoutingMixin) {
  private form: { file: File | null } = {
    file: null,
  };
  private rules: { required: boolean; mimes: Array<string>; size: number } = {
    required: true,
    mimes: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.oasis.opendocument.spreadsheet',
      'text/csv',
    ],
    size: 16 * 1024,
  };
  private requestBag = makeRequestBag({ data: [] as Array<App.ImportRequest> });

  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  async handleImport(): Promise<void> {
    const validate = await this.$refs.observer.validate();

    if (validate) {
      const importFile = await this.submit();
      if (importFile !== null) {
        this.$router.push({ name: 'import', params: { id: importFile.id.toString() } });
      }
    }
  }

  async submit(): Promise<{ id: number } | null> {
    if (this.form.file === null) {
      return null;
    }

    this.requestBag.reset({ data: [], loading: true });

    try {
      let formData = new FormData();
      formData.append('import[file]', this.form.file);
      const response = await this.$apiClient.post<{ data: { import: { id: number } } }>('/import/upload', formData);

      await this.$store.dispatch('importFile/init', { id: response.data.data.import.id, step: ImportStep.SETTINGS });

      this.requestBag.success = true;
      return response.data.data.import;
    } catch (e) {
      this.requestBag.error = true;
      this.requestBag.errorMessage = e.message;

      this.notify(NotificationMessage.error(e.message));
    } finally {
      this.requestBag.loading = false;
    }

    return null;
  }
}
