















































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { StepMixin } from '@/mixins/StepMixin';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { makeRequestBag } from '@/utils/request-bag';
import { NotificationMessage } from '@/services/notifications';
import NotifyMixin from '@/mixins/NotifyMixin';
import ModalConfirmationSettings from '@/components/ModalConfirmationSettings.vue';
import { ImportStep } from '@/types/enum';

@Component({
  components: { ModalConfirmationSettings, ValidationObserver, ValidationProvider },
})
export default class StepSettings extends Mixins(StepMixin, NotifyMixin) {
  private platforms: Array<App.Platform> = [];
  private siteBillingStatuses: Array<App.SiteBillingStatus> = [];
  private zohoStatuses: Array<App.ZohoStatus> = [];
  private endpoints: Array<App.ObjectName> = [];
  private requestBag = makeRequestBag({ data: [] as Array<App.Platform> });
  private siteBillingStatusesRequestBag = makeRequestBag({ data: [] as Array<App.SiteBillingStatus> });
  private zohoStatusesRequestBag = makeRequestBag({ data: [] as Array<App.ZohoStatus> });
  private endpointsRequestBag = makeRequestBag({ data: [] as Array<App.ObjectName> });
  private submitRequestBag = makeRequestBag({ data: [] as Array<App.ObjectName> });

  $refs!: {
    modalConfirmationSettings: ModalConfirmationSettings;
    observer: InstanceType<typeof ValidationObserver>;
  };

  get importFile(): App.Import {
    return this.$store.state.importFile.importFile;
  }

  async mounted(): Promise<void> {
    await Promise.all([
      this.fetchPlatforms(),
      this.fetchSiteBillingStatuses(),
      this.fetchZohoStatuses(),
      this.fetchEndpoints(),
    ]);
  }

  get themes(): Array<App.Theme> {
    const platform = this.platforms.find(platform => platform.id === this.importFile.baseSiteId);

    return typeof platform !== 'undefined' ? platform.themes : [];
  }

  async fetchPlatforms(): Promise<void> {
    this.requestBag.reset({ data: [], loading: true });

    try {
      const response = await this.$apiClient.get<{ platforms: Array<App.Platform> }>('/graphql/platforms');
      this.platforms = response.data.platforms;
    } catch (e) {
      // todo : call notifier
    } finally {
      this.requestBag.loading = false;
    }
  }

  async fetchSiteBillingStatuses(): Promise<void> {
    this.siteBillingStatusesRequestBag.reset({ data: [], loading: true });

    try {
      const response = await this.$apiClient.get<{ siteBillingStatuses: Array<App.SiteBillingStatus> }>(
        '/graphql/site/billing-statuses'
      );
      this.siteBillingStatuses = response.data.siteBillingStatuses;
    } catch (e) {
      // todo : call notifier
    } finally {
      this.siteBillingStatusesRequestBag.loading = false;
    }
  }

  async fetchZohoStatuses(): Promise<void> {
    this.zohoStatusesRequestBag.reset({ data: [], loading: true });

    try {
      const response = await this.$apiClient.get<{ zohoStatuses: Array<App.ZohoStatus> }>('/zoho/stages');
      this.zohoStatuses = response.data.zohoStatuses;
    } catch (e) {
      // todo : call notifier
    } finally {
      this.zohoStatusesRequestBag.loading = false;
    }
  }

  async fetchEndpoints(): Promise<void> {
    this.endpointsRequestBag.reset({ data: [], loading: true });

    try {
      const response = await this.$apiClient.get<{ endpoints: Array<App.ObjectName> }>('/endpoints');
      this.endpoints = response.data.endpoints;
    } catch (e) {
      // todo : call notifier
    } finally {
      this.endpointsRequestBag.loading = false;
    }
  }

  async openConfirmationModal(): Promise<void> {
    const isValid = await this.$refs.observer.validate();
    if (!isValid) {
      this.notify(NotificationMessage.error(this.$t('form.isNotValid').toString()));
      return;
    }

    this.$refs.modalConfirmationSettings.open(this.importFile);
  }

  async toNextStep(): Promise<void> {
    this.submitRequestBag.reset({ data: [], loading: true });

    const formData = {
      import_settings: {
        baseSiteId: this.importFile.baseSiteId,
        themeId: this.importFile.themeId,
        zohoStatus: this.importFile.zohoStatus,
        billingStatus: this.importFile.billingStatus,
        zohoManager: this.importFile.zohoManager,
        endpoint: this.importFile.endpoint,
        updateFile: this.importFile.updateFile,
        sendRegistrationEmail: this.importFile.sendRegistrationEmail,
      },
    };

    try {
      const response = await this.$apiClient.post<App.RequestApiResponse<App.Import>>(
        `/imports/${this.importFile.id}/settings`,
        formData
      );

      if (response.data.success) {
        await this.$store.dispatch('importFile/start', {
          importFile: response.data.data,
          step: ImportStep.SETTINGS,
        });
        this.$emit('toNextStep');
      }

      if (response.data.errors) {
        this.submitRequestBag.success = false;
        this.submitRequestBag.error = response.data.errors;
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } catch (e) {
      this.notify(NotificationMessage.error(e.message));
    } finally {
      this.submitRequestBag.loading = false;
    }
  }
}
