import { Component, Vue } from 'vue-property-decorator';

@Component
export class StepMixin extends Vue {
  returnToHome(): void {
    this.$emit('returnToHome');
  }

  async toNextStep(): Promise<void> {
    throw new Error('Not implemented error');
  }
}
