























































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ModalErrorMapping extends Vue {
  private visible: boolean = false;
  private importFile: App.Import | null = null;
  private errors: App.MappingErrors | null = null;

  open(importFile: App.Import, errors: App.MappingErrors): void {
    this.visible = true;
    this.importFile = importFile;
    this.errors = errors;
  }

  hide(): void {
    this.visible = false;
  }

  private onCancel(): void {
    this.$emit('returnToHome');
    this.hide();
  }
}
