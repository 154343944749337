





























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { makeRequestBag } from '@/utils/request-bag';
import { cloneDeep } from 'lodash-es';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

@Component({
  components: { ValidationObserver, ValidationProvider },
})
export default class ModalUpdateFileRow extends Vue {
  private requestBag = makeRequestBag({ data: [] });
  private visible: boolean = false;
  private fileRow: App.FileRow | null = null;
  private fileRowDraft: App.FileRow | null = null;

  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  @Watch('fileRow', {
    deep: true,
    immediate: true,
  })
  private fileRowWatcher(fileRow: App.FileRowError): void {
    this.fileRowDraft = cloneDeep(fileRow);
  }

  public open(fileRow: App.FileRow): void {
    this.fileRow = fileRow;
    this.visible = true;
  }

  public hide(): void {
    this.requestBag.reset();
    this.visible = false;
  }

  public onCancel(): void {
    this.hide();
  }

  public async onUpdate(): Promise<void> {
    if (null === this.fileRowDraft) {
      return;
    }

    if (!(await this.$refs.observer.validate())) {
      return;
    }

    this.$emit('updated', this.fileRowDraft);
    this.hide();
  }
}
