





















import { Component, Prop, Vue } from 'vue-property-decorator';
import FileRowAnomalyPopover from './FileRowAnomalyPopover.vue';

@Component({
  components: { FileRowAnomalyPopover },
})
export default class FileRowAnomaly extends Vue {
  @Prop({ required: true })
  private readonly fileRow!: App.FileRowError;

  private extendedPopover: boolean = false;

  get iconStyle(): { icon: string; color: string } {
    const errorsLength = this.fileRow.errors?.length || 0;
    const warningsLength = this.fileRow.warnings?.length || 0;

    return {
      icon:
        errorsLength > 0
          ? 'fas fa-exclamation-circle'
          : warningsLength > 0
          ? 'fas fa-exclamation-triangle'
          : 'far fa-check',
      color: errorsLength > 0 ? 'error' : warningsLength > 0 ? 'warning' : 'success',
    };
  }
}
