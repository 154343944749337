







































































import { Component, Mixins } from 'vue-property-decorator';
import { StepMixin } from '@/mixins/StepMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import { NotificationMessage } from '@/services/notifications';
import MappingColumnItem from '@/components/MappingColumnItem.vue';
import ModalErrorMapping from '@/components/ModalErrorMapping.vue';
import ModalUpdateMapping from '@/components/ModalUpdateMapping.vue';
import { makeRequestBag } from '@/utils/request-bag';

@Component({
  components: {
    MappingColumnItem,
    ModalErrorMapping,
    ModalUpdateMapping,
  },
})
export default class MappingStep extends Mixins(StepMixin, NotifyMixin) {
  private mappings: Array<App.Mapping> = [];
  private headers: Array<string> = ['valid', 'column', 'mappedBy', 'example', 'edit', 'ignore'];
  private mappingRequestBag = makeRequestBag({ data: [] as Array<App.ObjectName> });
  private requestBag = makeRequestBag({ data: [] as Array<App.ObjectName> });

  $refs!: {
    modalErrorMapping: ModalErrorMapping;
    modalUpdateMapping: ModalUpdateMapping;
    mappingItems: Array<MappingColumnItem>;
  };

  mounted(): void {
    this.mappings = [...this.$store.getters['importFile/currentImport'].mappings];
  }

  get isStoreReady(): boolean {
    return !this.$store.state.mapping.requestBag.loading;
  }

  get importFile(): App.Import {
    return this.$store.state.importFile.importFile;
  }

  private async validate(): Promise<void> {
    const response = await this.submitMapping();
    if (response && !response.success) {
      this.$refs.modalErrorMapping.open(this.importFile, response.errors);

      return;
    }

    return;
  }

  private handleOpenMappingModal(mapping: App.Mapping): void {
    this.$refs.modalUpdateMapping.open(mapping);
  }

  private async handleUpdatedMapping(mapping: App.Mapping): Promise<void> {
    this.mappingRequestBag.reset({ data: [], loading: true });
    try {
      const { fileRowCells, ...mappingInput } = mapping;
      const mappingRequestObject: App.MappingInput = {
        ...mappingInput,
        fileRowCells: fileRowCells.map((fileRowCell: App.FileRowCell) => {
          return fileRowCell['@id'];
        }),
      };

      const response = await this.$apiClient.put(mapping['@id'], mappingRequestObject);
      if (!response.data || !response.data?.id) {
        throw new Error(this.$t('apiRequest.error').toString());
      }

      this.notify(NotificationMessage.success(this.$t('step.mapping.alertMappingUpdated').toString()));

      if (this.mappings) {
        this.mappings = this.mappings.map(currentMapping =>
          currentMapping.id === mapping.id ? mapping : currentMapping
        );
        this.$store.dispatch('importFile/updateMappings', this.mappings);
      }
    } catch (e) {
      this.notify(NotificationMessage.error(e.message));
      throw e;
    } finally {
      this.mappingRequestBag.loading = false;
    }
  }

  private async submitMapping(): Promise<App.ApiMappingValidationResponse | null> {
    this.requestBag.reset({ data: [], loading: true });

    try {
      const response = await this.$apiClient.get<App.ApiMappingValidationResponse>(
        `/imports/${this.importFile.id}/mapping`
      );

      if (response.data.success) {
        this.$emit('toNextStep');

        return null;
      }

      return response.data;
    } catch (e) {
      this.notify(NotificationMessage.error(e.message));
    } finally {
      this.requestBag.loading = false;
    }

    return null;
  }
}
