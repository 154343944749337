











import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { MapperTypes } from '@/types/enum';
import MappingTypesProperty from '@/services/mapping-types-property';
import { container, SERVICE_MAPPING_TYPES_PROPERTY } from '@/container';

@Component
export default class MappingToggleNullableValue extends Vue {
  @Model('change', { required: true })
  private readonly isNullable!: string | null;

  @Prop({ required: true })
  private readonly mappingType!: MapperTypes;

  @Prop({ required: false, default: () => [] })
  private readonly errorMessages!: Array<string>;

  private mappingTypesProperty: MappingTypesProperty;

  constructor() {
    super();
    this.mappingTypesProperty = container.get<MappingTypesProperty>(SERVICE_MAPPING_TYPES_PROPERTY);
  }

  get currentMappingNullable(): string | null {
    return this.isNullable;
  }

  set currentMappingNullable(newValue: string | null) {
    this.$emit('change', newValue);
  }

  get mappingToggleNullable(): boolean {
    return this.mappingTypesProperty.canConfigureNullable(this.mappingType);
  }
}
