






import Stepper from '@/components/Stepper.vue';
import { Step } from '@/types/steps';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: { Stepper },
  metaInfo(this: ViewImport) {
    return {
      title: 'Import',
      titleTemplate:
        this.currentStep === null
          ? `%s – ${process.env.VUE_APP_NAME}`
          : `%s (${this.currentStep.title}) – ${process.env.VUE_APP_NAME}`,
    };
  },
})
export default class ViewImport extends Vue {
  currentStep: Step | null = null;
}
