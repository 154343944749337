import { Component, Vue } from 'vue-property-decorator';
import { formatDate } from '@/utils/date';

@Component
export default class DateMixin extends Vue {
  // eslint-disable-next-line class-methods-use-this
  formatDate(date: Date | number, format: string): string {
    return formatDate(date, format);
  }
}
