










































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { makeRequestBag } from '@/utils/request-bag';
import { MapperTypes } from '@/types/enum';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import MappingSelectExpectedValue from '@/components/MappingSelectExpectedValue.vue';
import MappingToggleNullableValue from '@/components/MappingToggleNullableValue.vue';
import MappingTypesProperty from '@/services/mapping-types-property';
import { container, SERVICE_MAPPING_TYPES_PROPERTY } from '@/container';

@Component({
  components: { MappingSelectExpectedValue, MappingToggleNullableValue, ValidationObserver, ValidationProvider },
})
export default class ModalUpdateMapping extends Vue {
  private requestBag = makeRequestBag({ data: [] });
  private visible: boolean = false;
  private mapping: App.Mapping | null = null;
  private mappingTypeValue: { id: number | null; useIds: boolean } = { id: null, useIds: false };
  private mappingTypesPropertyService: MappingTypesProperty;

  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  constructor() {
    super();
    this.mappingTypesPropertyService = container.get<MappingTypesProperty>(SERVICE_MAPPING_TYPES_PROPERTY);
  }

  get mappers(): Array<App.Mapper> {
    const mappers: Map<string, App.Mapper> | null = this.$store.state.mapping.mappers;
    if (null === mappers) {
      return [];
    }

    return [...mappers.entries()].map(([key, value]) => ({ key, ...value }));
  }

  get mapper(): App.Mapper | null {
    return this.$store.getters['mapping/getMapper'](this.mapping?.type);
  }

  get mappingDescription(): string {
    if (null === this.mapping) {
      return '';
    }

    return null !== this.mapper ? this.mapper.description : this.$t('step.mapping.noAssignedMapping').toString();
  }

  get typeValueRules(): Record<string, any> {
    return {
      required:
        this.mapping && this.mappingTypesPropertyService.canConfigureTypeValue(this.mapping.type as MapperTypes),
    };
  }

  @Watch('mapping.type')
  private mappingTypeWatcher(mappingType: MapperTypes | null): void {
    if (
      null !== this.mapping &&
      null !== mappingType &&
      !this.mappingTypesPropertyService.canConfigureTypeValue(mappingType)
    ) {
      this.mapping.typeValue = null;
    }
  }

  public open(mapping: App.Mapping): void {
    this.mapping = { ...mapping };

    if (null !== this.mapping.typeValue) {
      const typeValues = JSON.parse(this.mapping.typeValue);
      if (typeValues.id) {
        this.mappingTypeValue.id = typeValues.id;
      }

      if (typeValues.usedIds) {
        this.mappingTypeValue.useIds = typeValues.useIds;
      }
    }

    this.visible = true;
  }

  public hide(): void {
    this.requestBag.reset();
    this.visible = false;
  }

  public onCancel(): void {
    this.hide();
  }

  public async onUpdate(): Promise<void> {
    if (null === this.mapping) {
      return;
    }

    if (!(await this.$refs.observer.validate())) {
      return;
    }

    const mapping = this.formatMappingBeforeSubmit();
    if (!mapping) {
      return;
    }

    this.$emit('updated', mapping);
    this.hide();
  }

  private formatMappingBeforeSubmit(): App.Mapping | null {
    if (!this.mapper || null === this.mapping) {
      return null;
    }

    const mapping = { ...this.mapping };

    if (!this.mapper.optionNullable) {
      mapping.nullable = false;
    }

    const typeValues: { id?: number | null; useIds?: boolean } = {};
    if (this.mapper.expectedValue && null !== this.mappingTypeValue.id) {
      typeValues.id = this.mappingTypeValue.id;
    }

    if (this.mapper.canUseIds && this.mappingTypeValue.useIds) {
      typeValues.useIds = this.mappingTypeValue.useIds;
    }

    if (Object.keys(typeValues).length > 0) {
      mapping.typeValue = JSON.stringify(typeValues);
    }

    return mapping;
  }
}
