




































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ModalConfirmationSettings extends Vue {
  private visible: boolean = false;
  private importFile: App.Import | null = null;

  $refs!: {
    modal: Vue & {
      persistent: boolean;
    } /* "InstanceType<typeof VDialog>" does not work, see https://github.com/vuetifyjs/vuetify/issues/5962 */;
  };

  get mandatoryProperties(): Array<string> {
    if (this.importFile?.updateFile) {
      return ['id'];
    }

    return [
      'firstName',
      'lastName',
      'email',
      'address',
      'city',
      'zipCode',
      'phone',
      'openingHours',
      'zohoSector',
      'zohoIntegrator',
      'zohoPrescriber',
      'zohoType',
      'zohoPrice',
    ];
  }

  open(importFile: App.Import): void {
    this.visible = true;
    this.importFile = importFile;
  }

  hide(): void {
    this.visible = false;
  }

  private onCancel(): void {
    this.hide();
  }

  private onConfirm(): void {
    this.$emit('confirm');
    this.hide();
  }
}
