








































import { StepMixin } from '@/mixins/StepMixin';
import { ImportStep } from '@/types/enum';
import { Step } from '@/types/steps';
import { Component, Vue, Watch } from 'vue-property-decorator';
import StepDataValidation from './StepDataValidation.vue';
import StepImport from './StepImport.vue';
import StepMapping from './StepMapping.vue';
import StepSettings from './StepSettings.vue';
import Upload from './Upload.vue';

@Component({
  components: {
    Upload,
    StepSettings,
    StepMapping,
    StepDataValidation,
    StepImport,
  },
})
export default class Stepper extends Vue {
  private steps: Array<Step> = [
    {
      title: this.$t('step.settings.title').toString(),
      component: 'StepSettings',
      step: ImportStep.SETTINGS,
      ref: (): StepMixin => this.$refs.StepSettings[0],
    },
    {
      title: this.$t('step.mapping.title').toString(),
      component: 'StepMapping',
      step: ImportStep.MAPPING,
      ref: (): StepMixin => this.$refs.StepMapping[0],
    },
    {
      title: this.$t('step.dataValidation.title').toString(),
      component: 'StepDataValidation',
      step: ImportStep.ERROR_REPORT,
      ref: (): StepMixin => this.$refs.StepDataValidation[0],
    },
    {
      title: this.$t('step.import.title').toString(),
      component: 'StepImport',
      step: ImportStep.IMPORT,
      ref: (): StepMixin => this.$refs.StepImport[0],
    },
  ];

  get currentFileName(): string {
    return this.$store.getters['importFile/currentFilename'] ?? '';
  }

  get importFileStep(): ImportStep {
    return this.$store.state.importFile.step;
  }

  set importFileStep(step) {
    this.$store.dispatch('importFile/changeStep', step);
  }

  @Watch('importFileStep', { immediate: true })
  watchImportFileStep(importFileStep: ImportStep): void {
    this.$emit('input', this.steps[importFileStep]);
  }

  $refs!: {
    StepSettings: Array<StepMixin>;
    StepMapping: Array<StepMixin>;
    StepDataValidation: Array<StepMixin>;
    StepImport: Array<StepMixin>;
  };

  returnToHome(): void {
    this.$store.dispatch('importFile/reset');
    this.$store.dispatch('dataValidation/reset');
    this.$router.push({ name: 'home' });
  }

  async toNextStep(): Promise<void> {
    await this.$store.dispatch('importFile/changeStep', ImportStep.next(this.importFileStep));
  }
}
