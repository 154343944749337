






































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MappingColumnItem extends Vue {
  @Prop({ required: true })
  private readonly mapping!: App.Mapping;

  get isValid(): boolean {
    const mapper = this.$store.getters['mapping/getMapper'](this.mapping.type);

    if (this.mapping.ignored) {
      return true;
    }

    if (null === mapper) {
      return false;
    }

    if (mapper.expectedValue === null && this.mapping.type !== null) {
      return true;
    }

    return mapper.expectedValue !== null && this.mapping.typeValue !== null;
  }

  public validate(): boolean {
    return this.isValid;
  }

  get mappedBy(): string {
    const mapper = this.$store.getters['mapping/getMapper'](this.mapping.type);
    if (null !== mapper) {
      return mapper['name'];
    }

    return this.$t('step.mapping.noAssignedMapping').toString();
  }

  private handleUpdateClicked(): void {
    this.$emit('update', this.mapping);
  }

  private handleIgnoreChanged(): void {
    this.$emit('ignore', this.mapping);
  }
}
