/**
 * Sort by errors or warning present, then by position in file.
 * For example :
 * posInFile | errors / warnings
 *    83     | true
 *    86     | true
 *    1      | false
 *    2      | false
 */
export const sortFileRowErrors = (a: App.FileRowError, b: App.FileRowError): number => {
  const aAnomalyCount = a.errors.length + a.warnings.length;
  const bAnomalyCount = b.errors.length + b.warnings.length;

  return aAnomalyCount > 0 && bAnomalyCount > 0 ? a.positionInFile - b.positionInFile : bAnomalyCount - aAnomalyCount;
};
