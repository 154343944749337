var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-testid":"step-settings"}},[(_vm.importFile)?_c('VContainer',[(_vm.submitRequestBag.success === false)?_c('VAlert',{staticClass:"mt-2",attrs:{"type":"error","data-testid":"step-settings-form-errors"}},[_vm._v(" Attention ! Vous avez rencontré une ou plusieurs erreurs : "),_c('ul',{staticClass:"mt-2"},_vm._l((_vm.submitRequestBag.error),function(field,fieldKey){return _c('li',{key:fieldKey,staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(fieldKey)+" : "),_vm._l((field),function(error,key){return _c('span',{key:key,staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(error)+" ")])})],2)}),0)]):_vm._e(),_c('div',{staticClass:"d-flex justify-center align-center flex-column mt-5"},[_c('h1',{staticClass:"font-weight-bold",attrs:{"data-testid":"stepper:step-title"}},[_vm._v(_vm._s(_vm.$t('step.settings.title')))]),_vm._t("fileNameContainer")],2),_c('ValidationObserver',{ref:"observer"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Action"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VCol',{staticClass:"d-flex",attrs:{"data-testid":"settings-step:select-action-holder"}},[_c('VRadioGroup',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.importFile.updateFile),callback:function ($$v) {_vm.$set(_vm.importFile, "updateFile", $$v)},expression:"importFile.updateFile"}},[_c('VRadio',{staticClass:"createChoice",attrs:{"label":"Création","value":false,"data-testid":"settings-step:radio-action-create"}}),_c('VRadio',{staticClass:"editChoice",attrs:{"label":"Edition","value":true,"data-testid":"settings-step:radio-action-edit"}}),_c('div',{staticClass:"bgToggle"})],1)],1)]}}],null,false,1183915466)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Environnement"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VCol',{staticClass:"d-flex",attrs:{"data-testid":"settings-step:select-endpoint-holder"}},[_c('VAutocomplete',{attrs:{"items":_vm.endpoints,"error-messages":errors,"item-text":"name","item-value":"name","label":"Environnement","outlined":"","clearable":"","data-testid":"settings-step:select-endpoint"},model:{value:(_vm.importFile.endpoint),callback:function ($$v) {_vm.$set(_vm.importFile, "endpoint", $$v)},expression:"importFile.endpoint"}})],1)]}}],null,false,3201780404)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Plateforme"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VCol',{staticClass:"d-flex",attrs:{"data-testid":"settings-step:select-platform-holder"}},[_c('VAutocomplete',{attrs:{"items":_vm.platforms,"loading":_vm.requestBag.loading,"error-messages":errors,"item-text":"title","item-value":"id","label":"Plateforme","outlined":"","clearable":"","data-testid":"settings-step:select-platform"},on:{"change":function($event){_vm.importFile.themeId = null}},model:{value:(_vm.importFile.baseSiteId),callback:function ($$v) {_vm.$set(_vm.importFile, "baseSiteId", $$v)},expression:"importFile.baseSiteId"}})],1)]}}],null,false,71185206)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Thème"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VCol',{staticClass:"d-flex",attrs:{"data-testid":"settings-step:select-theme-holder"}},[_c('VAutocomplete',{attrs:{"disabled":!_vm.importFile.baseSiteId,"items":_vm.themes,"loading":_vm.requestBag.loading,"error-messages":errors,"item-text":"title","item-value":"id","label":"Thème","outlined":"","clearable":"","data-testid":"settings-step:select-theme"},model:{value:(_vm.importFile.themeId),callback:function ($$v) {_vm.$set(_vm.importFile, "themeId", $$v)},expression:"importFile.themeId"}})],1)]}}],null,false,132164762)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Statut contrat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VCol',{staticClass:"d-flex",attrs:{"data-testid":"settings-step:select-zoho-status-holder"}},[_c('VAutocomplete',{attrs:{"items":_vm.zohoStatuses,"error-messages":errors,"item-text":"name","item-value":"name","label":"Statut contrat","outlined":"","clearable":"","data-testid":"settings-step:select-zoho-status"},model:{value:(_vm.importFile.zohoStatus),callback:function ($$v) {_vm.$set(_vm.importFile, "zohoStatus", $$v)},expression:"importFile.zohoStatus"}})],1)]}}],null,false,1851845133)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Statut facturation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VCol',{staticClass:"d-flex",attrs:{"data-testid":"settings-step:select-billing-status-holder"}},[_c('VAutocomplete',{attrs:{"items":_vm.siteBillingStatuses,"error-messages":errors,"item-text":"description","item-value":"name","label":"Statut facturation","outlined":"","clearable":"","data-testid":"settings-step:select-billing-status"},model:{value:(_vm.importFile.billingStatus),callback:function ($$v) {_vm.$set(_vm.importFile, "billingStatus", $$v)},expression:"importFile.billingStatus"}})],1)]}}],null,false,1993607516)}),_c('ValidationProvider',{attrs:{"rules":{ required: true, email: true },"name":"Gestionnaire Zoho"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VCol',{staticClass:"d-flex",attrs:{"data-testid":"settings-step:input-zoho-manager-holder"}},[_c('VTextField',{attrs:{"error-messages":errors,"outlined":"","clearable":"","color":"primary","label":"Gestionnaire Zoho","data-testid":"settings-step:input-zoho-manager"},model:{value:(_vm.importFile.zohoManager),callback:function ($$v) {_vm.$set(_vm.importFile, "zohoManager", $$v)},expression:"importFile.zohoManager"}})],1)]}}],null,false,3244439214)}),_c('VCol',{staticClass:"d-flex py-0",attrs:{"data-testid":"settings-step:send-email-holder"}},[_c('VCheckbox',{attrs:{"label":"Envoyer un email de confirmation au client","data-testid":"settings-step:checkbox-send-email"},model:{value:(_vm.importFile.sendRegistrationEmail),callback:function ($$v) {_vm.$set(_vm.importFile, "sendRegistrationEmail", $$v)},expression:"importFile.sendRegistrationEmail"}})],1)],1),_c('ModalConfirmationSettings',{ref:"modalConfirmationSettings",on:{"confirm":_vm.toNextStep}})],1):_vm._e(),_c('VRow',{staticClass:"d-flex"},[_c('VBtn',{staticClass:"flex-1-1-0",attrs:{"x-large":"","tile":"","data-testid":"stepper:cancel-action","color":"lightPurple"},on:{"click":_vm.returnToHome}},[_vm._v(" "+_vm._s(_vm.$t('action.returnHome'))+" ")]),_c('VBtn',{staticClass:"flex-1-1-0 primary-gradient white--text rounded-br-lg",attrs:{"x-large":"","tile":"","data-testid":"stepper:next-action","loading":_vm.submitRequestBag.loading},on:{"click":_vm.openConfirmationModal}},[_vm._v(" "+_vm._s(_vm.$t('action.nextStep'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }