










































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ModalDataValidationWarning extends Vue {
  private visible: boolean = false;

  open(): void {
    this.visible = true;
  }

  hide(): void {
    this.visible = false;
  }

  private onCancel(): void {
    this.hide();
  }

  private onConfirm(): void {
    this.$emit('confirm');
    this.hide();
  }
}
