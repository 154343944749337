import { DataOptions } from 'vuetify';

export function convertVuetifyPaginationToApiPlatformPagination(
  vuetifyPagination: Partial<DataOptions>
): ApiPlatform.Pagination {
  const pagination: ApiPlatform.Pagination = {};

  pagination.pagination = false;
  if (vuetifyPagination.itemsPerPage !== -1) {
    pagination.pagination = true;
    pagination.page = vuetifyPagination.page;
    pagination.perPage = vuetifyPagination.itemsPerPage;
  }

  if (typeof vuetifyPagination.sortBy !== 'undefined' && vuetifyPagination.sortBy.length > 0) {
    pagination.sort = vuetifyPagination.sortBy.reduce((acc, by, index) => {
      if (typeof vuetifyPagination.sortDesc !== 'undefined') {
        acc[by] = vuetifyPagination.sortDesc[index] ? 'desc' : 'asc';
      }

      return acc;
    }, {} as NonNullable<ApiPlatform.Pagination['sort']>);
  }

  return pagination;
}
