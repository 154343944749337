
























import { Component, Mixins } from 'vue-property-decorator';
import FileRowAnomalyPopover from '@/components/FileRowAnomalyPopover.vue';
import FileRowAnomaly from '@/components/FileRowAnomaly.vue';
import FileRowImportStatusBadge from '@/components/FileRowImportStatusBadge.vue';
import FileRowItemMixin from '@/mixins/FileRowItemMixin';
import { FileRowImportStatus } from '@/types/enum';

@Component({
  components: {
    FileRowAnomaly,
    FileRowAnomalyPopover,
    FileRowImportStatusBadge,
  },
})
export default class FileRowImport extends Mixins(FileRowItemMixin) {
  get hasImportError(): boolean {
    return this.fileRow.currentStatus === FileRowImportStatus.ERROR;
  }
}
