

















































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import DateMixin from '@/mixins/DateMixin';
import { DataOptions, DataTableHeader } from 'vuetify';
import { makeRequestBag } from '@/utils/request-bag';
import { ImportStatus, ImportStep } from '@/types/enum';
import { convertVuetifyPaginationToApiPlatformPagination } from '@/utils/api-platform';
import ModalImportDelete from '@/components/ModalImportDelete.vue';
import RoutingMixin from '@/mixins/RoutingMixin';

@Component({
  components: { ModalImportDelete },
})
export default class ImportFileList extends Mixins(DateMixin, RoutingMixin) {
  private headers: Array<DataTableHeader> = [
    { text: this.$t('home.headers.user').toString(), value: 'user' },
    { text: this.$t('home.headers.filename').toString(), value: 'filename' },
    { text: this.$t('home.headers.sentAt').toString(), value: 'sentAt' },
    { text: this.$t('home.headers.rows').toString(), value: 'rows', sortable: false },
    { text: this.$t('home.headers.imported').toString(), value: 'status' },
    { text: this.$t('home.headers.edit').toString(), value: 'actions', sortable: false, class: 'actions-td' },
  ];
  private options: Pick<DataOptions, 'itemsPerPage' | 'page' | 'sortBy' | 'sortDesc'> = {
    itemsPerPage: 10,
    page: 1,
    sortBy: [],
    sortDesc: [],
  };
  private requestBag = makeRequestBag({ data: {} as ApiPlatform.Response<App.Import> });
  private totalItems: number | null = null;

  $refs!: {
    modalImportDelete: ModalImportDelete;
  };

  @Watch('options', { deep: true })
  async optionsChanged(): Promise<void> {
    await this.fetchImports();
  }

  async mounted(): Promise<void> {
    await this.fetchImports();
  }

  editItem(item: App.Import): void {
    this.$store.dispatch('importFile/start', {
      importFile: item,
      step: ImportStep.SETTINGS,
    });
    this.$router.push({ name: 'import', params: { id: item.id.toString() } });
  }

  openDeleteModal(item: App.Import): void {
    this.$refs.modalImportDelete.open(item);
  }

  getColorForStatus(status: ImportStatus): string {
    switch (status) {
      case ImportStatus.IMPORTED:
        return 'green--text lighten-2';
      case ImportStatus.PARTIALLY_IMPORTED:
        return 'orange--text lighten-2';
      case ImportStatus.NOT_IMPORTED:
        return 'red--text lighten-2';
      default:
        return 'blue--text lighten-2';
    }
  }

  getTextForStatus(status: ImportStatus): string {
    switch (status) {
      case ImportStatus.IMPORTED:
        return this.$t('label.yes').toString();
      case ImportStatus.PARTIALLY_IMPORTED:
        return this.$t('label.partially').toString();
      case ImportStatus.NOT_IMPORTED:
        return this.$t('label.no').toString();
      default:
        return this.$t('label.unknown').toString();
    }
  }

  async fetchImports(): Promise<void> {
    this.requestBag.reset({ data: {}, loading: true });
    const params = convertVuetifyPaginationToApiPlatformPagination(this.options);

    try {
      const response = await this.$apiClient.get<ApiPlatform.Response<App.Import>>('/api/imports', { params });

      if (!response.data['hydra:member'] || !response.data['hydra:totalItems']) {
        this.requestBag.error = true;
        this.requestBag.errorMessage = this.$t('apiRequest.error').toString();

        return;
      }

      this.requestBag.data = response.data;
      this.totalItems = response.data['hydra:totalItems'];
    } catch (e) {
      this.requestBag.error = true;
      this.requestBag.errorMessage = e;
    } finally {
      this.requestBag.loading = false;
    }
  }
}
