var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"d-flex align-center justify-space-between flex-wrap flex-wrap-reverse"},[_c('h5',{staticClass:"text-h5 ml-8 my-2 font-weight-bold",attrs:{"data-testid":"page-title"}},[_vm._v(_vm._s(_vm.$t('step.file.title')))]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('VBtn',{staticClass:"ma-2 custom-download-btn",attrs:{"href":_vm.generateApiRoute('/file-template/creation'),"outlined":"","color":"tertiary","small":""}},[_c('VIcon',{attrs:{"left":"","color":"primary"}},[_vm._v(" fal fa-arrow-to-bottom ")]),_vm._v(" "+_vm._s(_vm.$t('home.button.downloadCreationTemplate'))+" ")],1),_c('VBtn',{staticClass:"ma-2 custom-download-btn",attrs:{"href":_vm.generateApiRoute('/file-template/edition'),"outlined":"","color":"tertiary","small":""}},[_c('VIcon',{attrs:{"left":"","color":"primary"}},[_vm._v(" fal fa-arrow-to-bottom ")]),_vm._v(" "+_vm._s(_vm.$t('home.button.downloadEditionTemplate'))+" ")],1)],1)]),_c('VCard',{staticClass:"rounded-lg"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('ValidationProvider',{staticClass:"pa-4",attrs:{"rules":_vm.rules,"name":"Fichier","tag":"div","mode":"aggressive","custom-messages":{ mimes: 'Le type de fichier est invalide.' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('DropZone',{attrs:{"accept":_vm.rules.mimes.join(','),"data-testid":"upload-step:file-input","error-messages":errors,"class-dropzone":"rounded-lg"},on:{"input":validate},scopedSlots:_vm._u([{key:"file",fn:function(ref){
var file = ref.file;
return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(" Fichier sélectionné : ")]),_c('span',[_vm._v(" "+_vm._s(file.name)+" ")]),(valid)?_c('VIcon',{attrs:{"color":"green"}},[_vm._v("far fa-check")]):_c('VIcon',{attrs:{"color":"red"}},[_vm._v("fas fa-exclamation-triangle")])]}}],null,true),model:{value:(_vm.form.file),callback:function ($$v) {_vm.$set(_vm.form, "file", $$v)},expression:"form.file"}},[_c('p',[_c('span',{staticClass:"font-weight-medium d-block"},[_vm._v(" Glissez-déposez un fichier que vous souhaitez importer ")]),_c('span',{staticClass:"caption d-block"},[_vm._v(" Format acceptés : Excel (.xls, .xslx), LibreOffice Calc (.ods) ou CSV (.csv) ")])])])]}}],null,true)}),_c('VExpandTransition',[(_vm.form.file !== null && valid)?_c('div',[_c('VBtn',{staticClass:"flex-1-1-0 primary-gradient rounded-b-lg white--text",attrs:{"block":"","x-large":"","tile":"","data-testid":"stepper:next-action"},on:{"click":_vm.handleImport}},[_vm._v(" "+_vm._s(_vm.$t('action.import'))+" "),_c('VIcon',{staticClass:"ml-2",attrs:{"dark":""}},[_vm._v(" fal fa-upload ")])],1)],1):_vm._e()])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }