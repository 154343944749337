




























































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ModalConfirmationImport extends Vue {
  private visible: boolean = false;
  private choice: string = 'new';

  $refs!: {
    modal: Vue & {
      persistent: boolean;
    } /* "InstanceType<typeof VDialog>" does not work, see https://github.com/vuetifyjs/vuetify/issues/5962 */;
  };

  open(): void {
    this.visible = true;
  }

  hide(): void {
    this.visible = false;
    this.choice = 'new';
  }

  private onCancel(): void {
    this.hide();
  }

  private onConfirm(): void {
    this.$emit('confirm', { force: this.choice === 'all' });
    this.hide();
  }
}
