



















import { Component, Mixins } from 'vue-property-decorator';
import FileRowAnomalyPopover from '@/components/FileRowAnomalyPopover.vue';
import FileRowAnomaly from '@/components/FileRowAnomaly.vue';
import FileRowItemMixin from '@/mixins/FileRowItemMixin';

@Component({
  components: { FileRowAnomaly, FileRowAnomalyPopover },
})
export default class FileRowValidation extends Mixins(FileRowItemMixin) {
  private handleUpdateClicked(): void {
    this.$emit('update', this.fileRow);
  }
}
