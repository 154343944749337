































import { PrismEditor, highlight, languages } from '@/vendor/prismjs';

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ components: { PrismEditor } })
export default class FileRowDebugReportRequest extends Vue {
  @Prop({ required: true })
  private readonly report!: App.Report;

  public highlight(language: string) {
    return (code: string): string => highlight(code, languages.js, language);
  }

  public formatText(data: any): string {
    if (data !== null && typeof data === 'object') {
      return JSON.stringify(data, null, 4);
    }

    return data;
  }
}
