














import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { MapperTypes } from '@/types/enum';
import MappingTypesProperty from '@/services/mapping-types-property';
import { container, SERVICE_MAPPING_TYPES_PROPERTY } from '@/container';

interface ExpectedValueMapItem {
  getter: string;
  value: string;
  text: string;
}

@Component
export default class MappingSelectExpectedValue extends Vue {
  @Model('change', { required: true })
  private readonly mappingValue!: string | null;

  @Prop({ required: true })
  private readonly mappingType!: MapperTypes;

  @Prop({ required: false, default: () => [] })
  private readonly errorMessages!: Array<string>;

  private mappingTypesProperty: MappingTypesProperty;

  constructor() {
    super();
    this.mappingTypesProperty = container.get<MappingTypesProperty>(SERVICE_MAPPING_TYPES_PROPERTY);
  }

  get currentMappingValue(): string | null {
    return this.mappingValue;
  }

  set currentMappingValue(newValue: string | null) {
    this.$emit('change', newValue);
  }

  get items(): Array<App.CustomField> | Array<App.Category> | Array<App.Article> {
    const mappingItem = this.mappingTypesProperty.getTypeValue(this.mappingType);
    return undefined !== mappingItem ? this.$store.state.mapping.platform[mappingItem.getter] : [];
  }

  get itemValue(): string {
    const mappingItem = this.mappingTypesProperty.getTypeValue(this.mappingType);
    return undefined !== mappingItem ? mappingItem.value : '';
  }

  get itemText(): string {
    const mappingItem = this.mappingTypesProperty.getTypeValue(this.mappingType);
    return undefined !== mappingItem ? mappingItem.text : '';
  }

  get mappingRequireValue(): boolean {
    return this.mappingTypesProperty.canConfigureTypeValue(this.mappingType);
  }
}
