var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[_c('h5',{staticClass:"text-h5 ml-8 my-2 font-weight-bold",attrs:{"data-testid":"page-title"}},[_vm._v(_vm._s(_vm.$t('home.title')))]),_c('VCard',{staticClass:"rounded-lg"},[_c('VDataTable',{staticClass:"elevation-1 rounded-lg",attrs:{"headers":_vm.headers,"items":_vm.requestBag.data['hydra:member'],"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.requestBag.loading,"loading-text":_vm.$t('pagination.loading'),"mobile-breakpoint":"700","dark":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.user.firstName)+" "+_vm._s(item.user.lastName))]}},{key:"item.sentAt",fn:function(ref){
var item = ref.item;
return [(item.sentAt)?[_vm._v(" "+_vm._s(_vm.formatDate(new Date(item.sentAt), 'DD-MM-YYYY H:mm:ss'))+" ")]:_vm._e()]}},{key:"item.rows",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fileRows.length)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getColorForStatus(item.status)},[_vm._v(" "+_vm._s(_vm.getTextForStatus(item.status))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('VTooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":"","data-testid":("import-edit-" + (item.id))},on:{"click":function($event){$event.preventDefault();return (function () { return _vm.editItem(item); })($event)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v("fas fa-pen")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('action.edit'))+" ")]),_c('VTooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"href":_vm.generateApiRoute(("/import/download/" + (item.id))),"icon":"","data-testid":("import-download-" + (item.id))}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-arrow-to-bottom ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('action.download'))+" ")]),_c('VTooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":"","data-testid":("import-delete-" + (item.id))},on:{"click":function($event){$event.preventDefault();return (function () { return _vm.openDeleteModal(item); })($event)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v("fas fa-trash-alt")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('action.delete'))+" ")])]}}])}),_c('ModalImportDelete',{ref:"modalImportDelete",on:{"deleted":function () { return _vm.fetchImports(); }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }