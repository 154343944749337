












































import { Component, Vue } from 'vue-property-decorator';
import FileRowDebugReportRequest from './FileRowDebugReportRequest.vue';

@Component({
  components: { FileRowDebugReportRequest },
})
export default class ModalShowImportError extends Vue {
  private fileRow: App.FileRowError | null = null;
  private visible: boolean = false;
  private tab: string | null = null;

  open(item: App.FileRowError): void {
    this.visible = true;
    this.fileRow = item;
  }

  hide(): void {
    this.visible = false;
    this.fileRow = null;
  }

  private getReportId(reportIri: ApiPlatform.IRI): string {
    const reportId = reportIri.split('/').pop();

    if (undefined === reportId) {
      throw new Error(`Unable to extract Report ID. Given IRI is invalid, found : ${reportIri}`);
    }

    return reportId;
  }
}
