













































































































import { Component, Mixins } from 'vue-property-decorator';
import { DataOptions, DataTableHeader } from 'vuetify/types';
import { StepMixin } from '@/mixins/StepMixin';
import FileRowImport from './FileRowImport.vue';
import { FileRowImportStatus, MapperTypes } from '@/types/enum';
import FileRowItemMixin from '@/mixins/FileRowItemMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import { NotificationMessage } from '@/services/notifications';
import { makeRequestBag } from '@/utils/request-bag';
import { SERVICE_IMPORTER } from '@/container';
import Importer from '@/services/importer';
import ModalShowImportError from '@/components/ModalShowImportError.vue';
import ModalConfirmationImport from '@/components/ModalConfirmationImport.vue';

@Component({
  components: { ModalConfirmationImport, ModalShowImportError, FileRowImport },
})
export default class StepImport extends Mixins(StepMixin, NotifyMixin) {
  private static UPDATE_HEADERS = ['lineNumber', 'lineValid', 'siteId', 'status'];
  private static CREATE_HEADERS = ['lineNumber', 'lineValid', 'company', 'status'];

  private importRequestBag = makeRequestBag({ data: [] });
  private reloadRequestBag = makeRequestBag({ data: [] });
  private tableOptions: Partial<DataOptions> = {
    page: 1,
    itemsPerPage: 250,
  };
  private footerOptions = {
    itemsPerPageOptions: [50, 100, 250, -1],
  };
  private toggleFileRowImport: boolean = false;
  private selected: Array<App.FileRowError> = [];

  $refs!: {
    fileRowItems: Array<FileRowItemMixin<App.FileRowError> & FileRowImport>;
    modalShowError: ModalShowImportError;
    modalConfirmationImport: ModalConfirmationImport;
  };

  get isStoreReady(): boolean {
    return !this.$store.state.dataValidation.requestBag.loading && this.$store.state.dataValidation.initialized;
  }

  get currentImport(): App.Import {
    return this.$store.getters['importFile/currentImport'];
  }

  get headers(): Array<Partial<DataTableHeader>> {
    const currentHeaders = this.currentImport.updateFile ? StepImport.UPDATE_HEADERS : StepImport.CREATE_HEADERS;
    return currentHeaders.map(header => ({
      text: this.$t(`step.import.headers.${header}`).toString(),
      sortable: false,
      align: 'start',
    }));
  }

  get fileRows(): Array<App.FileRowError> {
    return this.currentImport.fileRows as App.FileRowError[];
  }

  get propertySelector(): MapperTypes {
    return this.currentImport.updateFile ? MapperTypes.SITE_ID : MapperTypes.ENTERPRISE;
  }

  private importClicked(): void {
    if (this.selected.filter(fileRow => fileRow.currentStatus !== FileRowImportStatus.NEW).length > 0) {
      this.$refs.modalConfirmationImport.open();
    } else {
      this.handleImportClicked(false);
    }
  }

  private async handleImportClicked(force: boolean): Promise<void> {
    let fileRowsIds: Array<number>;
    if (force) {
      fileRowsIds = this.selected.map(fileRow => fileRow.id);
    } else {
      fileRowsIds = this.selected
        .filter(fileRow => fileRow.currentStatus === FileRowImportStatus.NEW)
        .map(fileRow => fileRow.id);
    }

    if (fileRowsIds.length === 0) {
      const message = 'step.import.alerts.emptySelection';
      this.notify(NotificationMessage.info(this.$t(message).toString()));
      return;
    }

    try {
      this.importRequestBag.loading = true;
      const { data: report }: { data: App.PublishResponse } = await this.$apiClient.post(
        '/api/publish/file-rows',
        { file_rows: fileRowsIds },
        { params: { force } }
      );

      if (report.success) {
        this.notify(NotificationMessage.success(this.$t('step.import.alerts.importLaunched').toString()));
        this.setLaunchedStatus(fileRowsIds);
        this.reloadFileRows();
        return;
      }

      this.notify(NotificationMessage.error(this.$t('step.import.alerts.importFailed').toString()));
    } catch (e) {
      this.notify(NotificationMessage.error(e.message));
      throw e;
    } finally {
      this.importRequestBag.loading = false;
    }
  }

  private toggleSelect(status: boolean): void {
    if (!status) {
      this.selected = [];
      return;
    }

    this.fileRows.forEach(fileRow => {
      if (this.isSelectable(fileRow) && !this.selected.includes(fileRow)) {
        this.selected.push(fileRow);
      }
    });
  }

  private isSelectable(fileRow: App.FileRowError): boolean {
    if (fileRow.errors) {
      return fileRow.errors.length === 0;
    }
    return false;
  }

  private isSelected(item: App.FileRowError): boolean {
    return this.selected.includes(item);
  }

  private setIsSelected(item: App.FileRowError, status: boolean): void {
    if (!status) {
      this.selected = this.selected.filter(fileRow => fileRow.id !== item.id);
      return;
    }

    if (this.isSelectable(item) && !this.selected.includes(item)) {
      this.selected.push(item);
    }
  }

  private async reloadFileRows(): Promise<void> {
    const importId = this.currentImport.id;
    const importService = this.$container.get<Importer>(SERVICE_IMPORTER);

    try {
      this.reloadRequestBag.loading = true;
      this.selected = [];
      const validationReport = await importService.fetchImportErrors(importId);
      this.$store.dispatch('importFile/updateFileRowErrors', validationReport);
    } catch (e) {
      this.notify(NotificationMessage.error(this.$t('step.import.alerts.failedFileRowsReload').toString()));
      throw e;
    } finally {
      this.reloadRequestBag.loading = false;
    }
  }

  private setLaunchedStatus(fileRowsIds: Array<number>): void {
    this.fileRows.forEach(fileRow => {
      if (fileRowsIds.includes(fileRow.id)) {
        fileRow.currentStatus = FileRowImportStatus.POST;
      }
    });
  }

  private handleShowError(fileRow: App.FileRowError): void {
    this.$refs.modalShowError.open(fileRow);
  }
}
