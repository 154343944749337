







































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationErrorCategory } from '@/types/enum';

@Component
export default class FileRowAnomalyPopover extends Vue {
  @Prop({ required: true })
  private readonly fileRow!: App.FileRowError;

  get hasErrors(): boolean {
    return this.errors.length > 0;
  }

  get hasWarnings(): boolean {
    return this.warnings.length > 0;
  }

  get errors(): Array<string> {
    return this.fileRow.errors.map(error => {
      if (ValidationErrorCategory.ERROR === error.category) {
        return this.$t(`step.dataValidation.mappingType.${error.message}`).toString();
      }

      const mapper: App.Mapper | null = this.$store.getters['mapping/getMapper'](error.message);

      if (null === mapper) {
        return error.message;
      }

      return this.$t(`step.dataValidation.mappingType.empty`, { field: mapper.name }).toString();
    });
  }

  get warnings(): Array<string> {
    return this.fileRow.warnings.map(warning =>
      this.$t(`step.dataValidation.mappingType.${warning.message}`).toString()
    );
  }
}
